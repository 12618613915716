import { isNumber } from 'lodash';
import { useContext, createContext } from 'react';

import { Box } from '@mui/material';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';

const AuthContext = createContext(null);

export const useAuth = () => {
    return useContext(AuthContext);
};

const RequestStatus = ({ status, statusList, onlyColor }) => {
    statusList = statusList || [];
    let state;
    if (typeof status != 'object') {
        let f = statusList.find((item) => item._id == status);
        if (!f) {
            state = statusList[0];
        } else {
            state = f;
        }
    } else {
        state = status;
    }
    return (
        <>
            <Box
                component="span"
                style={{ position: 'relative' }}
                sx={{
                    width: '20px',
                    height: '20px',
                    bgcolor: state.color,
                    borderRadius: '15px',
                    display: 'inline-block',
                    mr: 1,
                    verticalAlign: 'top'
                }}
            ></Box>
            {!onlyColor && state.name}
        </>
    );
};

export const getType = (type) => {
    switch (type) {
        case 'basic':
            return 'Basic';
        case '3d-printer':
            return '3D Printer';
        case 'cnc':
            return 'CNC';
        case 'laser-cutter':
            return 'Laser Cutter';
        case 'vinyl-cutter':
            return 'Vinyl Cutter';
        default:
            return type;
    }
};

export const getIcon = (type) => {
    switch (type) {
        case '3d-formular':
            return <CoffeeMakerIcon />;
        case '3d-printer':
            return <CoffeeMakerIcon />;
        case 'cnc':
            return <CoffeeMakerIcon />;
        case 'laser-cutter':
            return <CoffeeMakerIcon />;
        case 'vinyl-cutter':
            return <CoffeeMakerIcon />;
        default:
            return <CoffeeMakerIcon />;
    }
};

const formatFilesize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const round = (num) => {
    return Math.round(num * 1000) / 1000;
};

export { RequestStatus, formatFilesize, round };
