import { useMemo } from 'react';

import useAuth from './useAuth';

function useIsQuoter() {
    const { client } = useAuth();

    const isBasicPrinter = useMemo(() => {
        return client.selectedQuoter.type == 'basic';
    }, [client.selectedQuoter]);
    const isBasicQuoter = isBasicPrinter;

    const enableExtendedConfiguration = useMemo(() => {
        return client.selectedQuoter.type != 'basic' || client.selectedQuoter.enableExtendedConfiguration;
    }, [client.selectedQuoter]);

    const isBasicExtendedConfiguration = useMemo(() => {
        return client.selectedQuoter.type === 'basic' && client.selectedQuoter.enableExtendedConfiguration;
    }, [client.selectedQuoter]);

    return { isBasicPrinter, isBasicQuoter, enableExtendedConfiguration, isBasicExtendedConfiguration };
}

export default useIsQuoter;
