// assets
import { DashboardOutlined, GroupOutlined, RadarChartOutlined, BgColorsOutlined } from '@ant-design/icons';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import SeetingsIcon from '@mui/icons-material/Settings';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TextFieldsIcon from '@mui/icons-material/TextFields';

// icons
const icons = {
    DashboardOutlined,
    GroupOutlined,
    RadarChartOutlined,
    BgColorsOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const configuration = {
    id: 'group-configuration',
    title: 'Quoter',
    type: 'group',
    children: [
        {
            id: 'process',
            title: 'Process',
            type: 'item',
            url: '/process/overview',
            icon: PrecisionManufacturingIcon,
            quoter: true,
            extendedSetting: true
        },
        {
            id: 'material-category',
            title: 'Material Category',
            type: 'item',
            url: '/material-category/overview',
            icon: icons.GroupOutlined,
            quoter: true,
            extendedSetting: true
        },
        {
            id: 'material',
            title: 'Material',
            type: 'item',
            url: '/material/overview',
            icon: icons.RadarChartOutlined,
            quoter: true,
            extendedSetting: true
        },
        {
            id: 'color',
            title: 'Color',
            type: 'item',
            url: '/color/overview',
            icon: InvertColorsIcon
        },
        {
            id: 'fields',
            title: 'Fields',
            type: 'item',
            url: '/fields',
            icon: TextFieldsIcon,
            quoter: true
        },
        {
            id: 'option',
            title: 'Settings',
            type: 'item',
            url: '/option/overview',
            icon: SeetingsIcon,
            quoter: true
        },
        {
            id: 'layout',
            title: 'Layout',
            type: 'item',
            url: '/layout',
            icon: BgColorsOutlined,
            quoter: true
        },
        {
            id: 'notification',
            title: 'Notifications',
            type: 'item',
            url: '/notification',
            icon: NotificationsIcon,
            quoter: true
        },
        {
            id: 'include',
            title: 'Include',
            type: 'item',
            url: '/include/overview',
            icon: PresentToAllIcon,
            quoter: true
        }
    ]
};

export default configuration;
