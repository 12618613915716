import {
    Typography,
    TextField,
    Grid,
    Box,
    InputAdornment,
    Button,
    Switch,
    Slider,
    Stack,
    FormHelperText,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    FormControlLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemAvatar,
    Avatar,
    Divider
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { useEffect, useState, useRef } from 'react';
import { useAlert } from '@blaumaus/react-alert';
import { useAuth } from 'components/AuthProvider';

import { HexColorPicker } from 'react-colorful';
import request from 'utils/request';

import * as Yup from 'yup';
import { Formik } from 'formik';

import { getIcon, getType } from 'utils/utils';

const QuoterSelect = () => {
    const alert = useAlert();
    const { client, setClient } = useAuth();
    const [open, setOpen] = useState(true);

    const [loading, setLoading] = useState(false);

    const selectQuoter = async (quoter) => {
        try {
            setLoading(true);
            const json = await request('/quoter/set', { quoterId: quoter._id }, {});
            if (json === false) {
                console.log('Unauth access');
                return;
            }

            if (json.r === 1) {
                setClient({ ...client, selectedQuoter: json.quoter });
            }
        } catch (err) {
            console.log(err);
            alert.error('Error adding quoter');
        }
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Select Quoter</DialogTitle>
            <DialogContent>
                <DialogContentText>Please selet a quoter to continue</DialogContentText>
                <List sx={{ width: '340px', maxWidth: '100%' }}>
                    {client.quoters.map((quoter) => (
                        <ListItem key={quoter.id} disablePadding>
                            <ListItemButton onClick={() => selectQuoter(quoter)}>
                                <ListItemAvatar>
                                    <Avatar>{getIcon(quoter.type)}</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={quoter.name} secondary={getType(quoter.type)} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    <Divider />
                </List>
            </DialogContent>
        </Dialog>
    );
};

export default QuoterSelect;
