// project import
import pages from './pages';
import dashboard from './dashboard';
import utilities from './utilities';
import support from './support';
import configuration from './configuration';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, configuration]
};

export default menuItems;
