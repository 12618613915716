import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { EditOutlined, ProfileOutlined, LogoutOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';
import StoreIcon from '@mui/icons-material/Store';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import LogoutIcon from '@mui/icons-material/Logout';
import PaymentIcon from '@mui/icons-material/Payment';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { activeItem } from 'store/reducers/menu';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const menu = useSelector((state) => state.menu);
    const { drawerOpen, openItem } = menu;

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const handleListItemClick = (event, index, href) => {
        setSelectedIndex(index);
        dispatch(activeItem({ openItem: [href] }));
        navigate('/' + href);
    };

    useEffect(() => {
        const path = window.location.pathname;
        if (path === '/profil') {
            setSelectedIndex(0);
            dispatch(activeItem({ openItem: ['profil'] }));
        } else if (path === '/settings') {
            setSelectedIndex(1);
            dispatch(activeItem({ openItem: ['settings'] }));
        } else if (path === '/status') {
            setSelectedIndex(2);
            dispatch(activeItem({ openItem: ['status'] }));
        } else if (path === '/billing') {
            setSelectedIndex(4);
            dispatch(activeItem({ openItem: ['billing'] }));
        }
    }, []);
    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
            <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0, 'profil')}>
                <ListItemIcon>
                    <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Account" />
            </ListItemButton>
            <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1, 'settings')}>
                <ListItemIcon>
                    <StoreIcon />
                </ListItemIcon>
                <ListItemText primary="Company" />
            </ListItemButton>

            <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2, 'status')}>
                <ListItemIcon>
                    <SettingsBrightnessIcon />
                </ListItemIcon>
                <ListItemText primary="Status" />
            </ListItemButton>
            <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3, 'billing')}>
                <ListItemIcon>
                    <SettingsBrightnessIcon />
                </ListItemIcon>
                <ListItemText primary="Billing" />
            </ListItemButton>
            <ListItemButton selected={selectedIndex === 5} onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </List>
    );
};

ProfileTab.propTypes = {
    handleLogout: PropTypes.func
};

export default ProfileTab;
