import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton, Typography } from '@mui/material';

const AlertTemplate = ({ message, options, style, close }) => {
    const closeBtn = (
        <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
                console.log('click');
                close();
            }}
        >
            <CloseIcon fontSize="inherit" />
        </IconButton>
    );
    return (
        <Alert variant="outlined" sx={{ backgroundColor: '#fff' }} action={closeBtn} severity={options.type}>
            <Typography>{message}</Typography>
        </Alert>
    );
};

export default AlertTemplate;
