import { Outlet } from 'react-router-dom';
import AuthProvider from 'components/AuthProvider';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <>
        <AuthProvider noPriv>
            <Outlet />
        </AuthProvider>
    </>
);

export default MinimalLayout;
