import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Quoter from 'pages/client/Quoter';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const ColorsPage = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

const Processes = Loadable(lazy(() => import('pages/configuration/Processes')));
const Process = Loadable(lazy(() => import('pages/configuration/Process')));
const ProcessAdd = Loadable(lazy(() => import('pages/configuration/ProcessAdd')));

const Materials = Loadable(lazy(() => import('pages/configuration/Materials')));
const Material = Loadable(lazy(() => import('pages/configuration/Material')));
const MaterialAdd = Loadable(lazy(() => import('pages/configuration/MaterialAdd')));

const MaterialCategories = Loadable(lazy(() => import('pages/configuration/MaterialCategories')));
const MaterialCategory = Loadable(lazy(() => import('pages/configuration/MaterialCategory')));
const MaterialCategoryAdd = Loadable(lazy(() => import('pages/configuration/MaterialCategoryAdd')));

const Colors = Loadable(lazy(() => import('pages/configuration/Colors')));

const Option = Loadable(lazy(() => import('pages/configuration/Option')));
const Include = Loadable(lazy(() => import('pages/configuration/Include')));
const Notification = Loadable(lazy(() => import('pages/configuration/Notification')));

const Requests = Loadable(lazy(() => import('pages/request/Requests')));
const Request = Loadable(lazy(() => import('pages/request/Request')));
const Archive = Loadable(lazy(() => import('pages/request/Archive')));
const Trash = Loadable(lazy(() => import('pages/request/Trash')));
const ProtectedRoute = Loadable(lazy(() => import('components/ProtectedRoute')));

const Profil = Loadable(lazy(() => import('pages/client/Profil')));
const Settings = Loadable(lazy(() => import('pages/client/Settings')));
const Billing = Loadable(lazy(() => import('pages/client/Billing')));
const Status = Loadable(lazy(() => import('pages/client/Status')));

const Error404 = Loadable(lazy(() => import('pages/extra-pages/Error404')));

const Fields = Loadable(lazy(() => import('pages/configuration/Fields')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'profil',
            element: <Profil />
        },
        {
            path: 'settings',
            element: <Settings />
        },
        {
            path: 'status',
            element: <Status />
        },
        {
            path: 'billing',
            element: <Billing />
        },
        {
            path: ':quoter/layout',
            element: <Quoter />
        },
        {
            path: ':quoter/request',
            element: <ProtectedRoute />,
            children: [
                {
                    path: 'dashboard',
                    element: <DashboardDefault />
                },
                {
                    path: 'overview',
                    element: <Requests />
                },
                {
                    path: 'archive',
                    element: <Archive />
                },
                {
                    path: 'trash',
                    element: <Trash />
                },
                {
                    path: 'edit/:id',
                    element: <Request />
                }
            ]
        },
        {
            path: ':quoter/process',
            element: <ProtectedRoute />,
            children: [
                {
                    path: 'overview',
                    element: <Processes />
                },
                {
                    path: 'add',
                    element: <ProcessAdd />
                },
                {
                    path: 'edit/:id',
                    element: <Process />
                }
            ]
        },
        {
            path: ':quoter/material',
            element: <ProtectedRoute />,
            children: [
                {
                    path: 'overview',
                    element: <Materials />
                },
                {
                    path: 'add',
                    element: <MaterialAdd />
                },
                {
                    path: 'edit/:id',
                    element: <Material />
                }
            ]
        },
        {
            path: ':quoter/material-category',
            element: <ProtectedRoute />,
            children: [
                {
                    path: 'overview',
                    element: <MaterialCategories />
                },
                {
                    path: 'add',
                    element: <MaterialCategoryAdd />
                },
                {
                    path: 'edit/:id',
                    element: <MaterialCategory />
                }
            ]
        },
        {
            path: 'color',
            element: <ProtectedRoute />,
            children: [
                {
                    path: 'overview',
                    element: <Colors />
                }
            ]
        },
        {
            path: ':quoter/option',
            element: <ProtectedRoute />,
            children: [
                {
                    path: 'overview',
                    element: <Option />
                }
            ]
        },
        {
            path: ':quoter/include',
            element: <ProtectedRoute />,
            children: [
                {
                    path: 'overview',
                    element: <Include />
                }
            ]
        },
        {
            path: ':quoter/notification',
            element: <ProtectedRoute />,
            children: [
                {
                    path: '',
                    element: <Notification />
                }
            ]
        },
        {
            path: ':quoter/fields',
            element: <ProtectedRoute />,
            children: [
                {
                    path: '',
                    element: <Fields />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'shadow',
            element: <Shadow />
        },
        {
            path: 'typography',
            element: <Typography />
        },
        {
            path: 'icons/ant',
            element: <AntIcons />
        },
        {
            path: '*',
            element: <Error404 />
        }
    ]
};

export default MainRoutes;
