// assets
import { DashboardOutlined, GroupOutlined, RadarChartOutlined, BgColorsOutlined } from '@ant-design/icons';
import ViewListIcon from '@mui/icons-material/ViewList';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';

// icons
const icons = {
    DashboardOutlined,
    GroupOutlined,
    RadarChartOutlined,
    BgColorsOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-request',
    title: 'Leads',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/request/dashboard',
            icon: icons.DashboardOutlined,
            breadcrumbs: false,
            quoter: true
        },
        {
            id: 'leads',
            title: 'Overview',
            type: 'item',
            url: '/request/overview',
            icon: ViewListIcon,
            quoter: true
        },
        {
            id: 'leadsArchive',
            title: 'Archive',
            type: 'item',
            url: '/request/archive',
            icon: ArchiveIcon,
            quoter: true
        },
        {
            id: 'leadsTrash',
            title: 'Trash',
            type: 'item',
            url: '/request/trash',
            icon: DeleteIcon,
            quoter: true
        }
    ]
};

export default dashboard;
