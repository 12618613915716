import {
    Box,
    Grid,
    TextField,
    FormControl,
    Button,
    Select,
    MenuItem,
    Stack,
    InputLabel,
    Switch,
    FormControlLabel,
    Tabs,
    Tab
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { MuiColorInput } from 'mui-color-input';

import MainCard from 'components/MainCard';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '@blaumaus/react-alert';

import request from 'utils/request';
import { useAuth } from 'components/AuthProvider';

const Quoter = (props) => {
    const { client, setClient } = useAuth();
    const [quoter, setQuoter] = useState(null);
    const [calculatorKey, setCalculatorKey] = useState('');
    const [tabValue, setTabValue] = useState(0);

    const navigate = useNavigate();
    const alert = useAlert();

    const calculatorRef = useRef();

    const onSubmitCalculator = async () => {
        try {
            const values = {
                quoterId: client.selectedQuoter._id,
                uploadButtonLabel: quoter.uploadButtonLabel || '',
                contactButtonLabel: quoter.contactButtonLabel || '',
                downloadButtonLabel: quoter.downloadButtonLabel || '',
                partBoxLabel: quoter.partBoxLabel || '',
                configurationBoxLabel: quoter.configurationBoxLabel || '',
                contactBoxLabel: quoter.contactBoxLabel || '',
                contactBoxText: quoter.contactBoxText || '',
                contactFinishedLabel: quoter.contactFinishedLabel || '',
                contactFinishedText: quoter.contactFinishedText || '',
                floorStyle: quoter.floorStyle || 'chessboard',
                styleMode: quoter.styleMode || 'light',
                customStyle: quoter.customStyle || false,
                bgcolor: quoter.bgcolor || '',
                textColor: quoter.textColor || '',
                primaryColor: quoter.primaryColor || '',
                secondaryColor: quoter.secondaryColor || '',
                errorColor: quoter.errorColor || '',
                warningColor: quoter.warningColor || '',
                infoColor: quoter.infoColor || '',
                successColor: quoter.successColor || ''
                /* boxColor: quoter.boxColor || '',
                buttonColor: quoter.buttonColor || '',
                buttonTextColor: quoter.buttonTextColor || '',
                buttonHoverColor: quoter.buttonHoverColor || '',
                buttonHoverTextColor: quoter.buttonHoverTextColor || '',
                borderColor: quoter.borderColor || '' */
            };

            const json = await request('/calculator', values, {});
            if (json === false) {
                console.log('Unauth access');
                return;
            }
            if (json.r == 1) {
                alert.success('Calculator updated');
                setCalculatorKey(process.env.REACT_APP_FRONTEND_PATH + 'client/' + client.selectedQuoter.key + '?t=' + Date.now());
            } else {
                alert.error(json.msg);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const action = (
        <>
            <Button style={{ marginRight: '5px' }} startIcon={<CheckIcon />} variant="contained" type="submit">
                Save
            </Button>
        </>
    );

    useEffect(() => {
        setQuoter(client.selectedQuoter);
        setCalculatorKey(process.env.REACT_APP_FRONTEND_PATH + 'client/' + client.selectedQuoter.key);
    }, [client]);

    useEffect(() => {
        if (!calculatorRef.current) return;
        const x = calculatorRef.current.contentWindow;
        x.postMessage({ type: 'editorChanged', user: quoter }, '*');
    }, [quoter, calculatorRef.current]);
    if (!quoter) return;

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{}}
                validationSchema={Yup.object().shape({})}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        onSubmitCalculator();
                        setStatus({ success: false });
                        setSubmitting(false);
                    } catch (err) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
                    return (
                        <form noValidate onSubmit={handleSubmit}>
                            <MainCard sx={{ mt: 2 }} title="Quoter Layout" actions={action}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Stack spacing={2} sx={{ mb: 2 }}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs
                                                    value={tabValue}
                                                    onChange={(e, value) => setTabValue(value)}
                                                    aria-label="quoter layout tabs"
                                                >
                                                    <Tab label="Style" />
                                                    <Tab label="Labels" />
                                                </Tabs>
                                            </Box>
                                            {tabValue === 0 && (
                                                <>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="floorStyle-label">Floor Style</InputLabel>
                                                        <Select
                                                            variant="outlined"
                                                            name="floorStyle"
                                                            value={quoter.floorStyle || 'chessboard'}
                                                            onChange={(e) => {
                                                                setQuoter({ ...quoter, floorStyle: e.target.value });
                                                                handleChange(e);
                                                            }}
                                                        >
                                                            <MenuItem value="chessboard">Chessboard</MenuItem>
                                                            <MenuItem value="chessboard-colorless">Chessboard colorless</MenuItem>
                                                            <MenuItem value="white">White</MenuItem>
                                                            <MenuItem value="black">Black</MenuItem>
                                                            <MenuItem value="transparent">Transparent</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="floorStyle-label">Light/Dark Mode</InputLabel>
                                                        <Select
                                                            variant="outlined"
                                                            name="styleMode"
                                                            value={quoter.styleMode || 'light'}
                                                            onChange={(e) => {
                                                                setQuoter({ ...quoter, styleMode: e.target.value });
                                                            }}
                                                        >
                                                            <MenuItem value="light">Light</MenuItem>
                                                            <MenuItem value="dark">Dark</MenuItem>
                                                            <MenuItem value="system">System</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={quoter.customStyle}
                                                                onChange={(e) => {
                                                                    setQuoter({ ...quoter, customStyle: e.target.checked });
                                                                    handleChange(e);
                                                                }}
                                                                name="customStyle"
                                                            />
                                                        }
                                                        label="Enable Custom Style"
                                                    />
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink={true} id="bgcolor">
                                                            Background Color
                                                        </InputLabel>
                                                        <MuiColorInput
                                                            disabled={props.loading}
                                                            value={quoter.bgcolor || ''}
                                                            onChange={(color) => {
                                                                setQuoter({ ...quoter, bgcolor: color });
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink={true} id="textColor">
                                                            Text Color
                                                        </InputLabel>
                                                        <MuiColorInput
                                                            disabled={props.loading}
                                                            value={quoter.textColor || ''}
                                                            onChange={(color) => {
                                                                setQuoter({ ...quoter, textColor: color });
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink={true} id="primaryColor">
                                                            Primary color
                                                        </InputLabel>
                                                        <MuiColorInput
                                                            disabled={props.loading}
                                                            value={quoter.primaryColor || ''}
                                                            onChange={(color) => {
                                                                setQuoter({ ...quoter, primaryColor: color });
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink={true} id="secondaryColor">
                                                            Secondary color
                                                        </InputLabel>
                                                        <MuiColorInput
                                                            disabled={props.loading}
                                                            value={quoter.secondaryColor || ''}
                                                            onChange={(color) => {
                                                                setQuoter({ ...quoter, secondaryColor: color });
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink={true} id="errorColor">
                                                            Error color
                                                        </InputLabel>
                                                        <MuiColorInput
                                                            disabled={props.loading}
                                                            value={quoter.errorColor || ''}
                                                            onChange={(color) => {
                                                                setQuoter({ ...quoter, errorColor: color });
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink={true} id="warningColor">
                                                            Warning color
                                                        </InputLabel>
                                                        <MuiColorInput
                                                            disabled={props.loading}
                                                            value={quoter.warningColor || ''}
                                                            onChange={(color) => {
                                                                setQuoter({ ...quoter, warningColor: color });
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink={true} id="infoColor">
                                                            Info color
                                                        </InputLabel>
                                                        <MuiColorInput
                                                            disabled={props.loading}
                                                            value={quoter.infoColor || ''}
                                                            onChange={(color) => {
                                                                setQuoter({ ...quoter, infoColor: color });
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink={true} id="successColor">
                                                            Success color
                                                        </InputLabel>
                                                        <MuiColorInput
                                                            disabled={props.loading}
                                                            value={quoter.successColor || ''}
                                                            onChange={(color) => {
                                                                setQuoter({ ...quoter, successColor: color });
                                                            }}
                                                        />
                                                    </FormControl>
                                                </>
                                            )}
                                            {tabValue === 1 && (
                                                <>
                                                    <TextField
                                                        id="uploadButtonLabel"
                                                        label="Upload Button Label"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Upload model"
                                                        value={quoter.uploadButtonLabel || ''}
                                                        onChange={(e) => {
                                                            setQuoter({ ...quoter, uploadButtonLabel: e.target.value });
                                                        }}
                                                    />
                                                    <TextField
                                                        id="contactButtonLabel"
                                                        label="Contact Button Label"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Get quote"
                                                        value={quoter.contactButtonLabel || ''}
                                                        onChange={(e) => {
                                                            setQuoter({ ...quoter, contactButtonLabel: e.target.value });
                                                        }}
                                                    />
                                                    <TextField
                                                        id="downloadButtonLabel"
                                                        label="PDF Download Button Label"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Download Quotation"
                                                        value={quoter.downloadButtonLabel || ''}
                                                        onChange={(e) => {
                                                            setQuoter({ ...quoter, downloadButtonLabel: e.target.value });
                                                        }}
                                                    />
                                                    <TextField
                                                        id="partBoxLabel"
                                                        label="Part Box Label"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Models"
                                                        value={quoter.partBoxLabel || ''}
                                                        onChange={(e) => {
                                                            setQuoter({ ...quoter, partBoxLabel: e.target.value });
                                                        }}
                                                    />
                                                    <TextField
                                                        id="configurationBoxLabel"
                                                        label="Configuration Box Label"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Configuration"
                                                        value={quoter.configurationBoxLabel || ''}
                                                        onChange={(e) => {
                                                            setQuoter({ ...quoter, configurationBoxLabel: e.target.value });
                                                        }}
                                                    />
                                                    <TextField
                                                        id="contactBoxLabel"
                                                        label="Contact Box Label"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Instant Quote"
                                                        value={quoter.contactBoxLabel || ''}
                                                        onChange={(e) => {
                                                            setQuoter({ ...quoter, contactBoxLabel: e.target.value });
                                                        }}
                                                    />
                                                    <TextField
                                                        id="contactBoxText"
                                                        label="Contact Box Text"
                                                        type="text"
                                                        multiline
                                                        rows={4}
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Click on CONTACT US to submit the request to our team. We will contact you shortly. You can also download the quote as a PDF."
                                                        value={quoter.contactBoxText || ''}
                                                        onChange={(e) => {
                                                            setQuoter({ ...quoter, contactBoxText: e.target.value });
                                                        }}
                                                    />
                                                    <TextField
                                                        id="contactFinishedLabel"
                                                        label="Contact Finished Label"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Reqeust submitted"
                                                        value={quoter.contactFinishedLabel || ''}
                                                        onChange={(e) => {
                                                            setQuoter({ ...quoter, contactFinishedLabel: e.target.value });
                                                        }}
                                                    />
                                                    <TextField
                                                        id="contactFinishedText"
                                                        label="Contact Finished Text"
                                                        type="text"
                                                        multiline
                                                        rows={4}
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Thank you for your request. We will contact you shortly. You can also download the quote as a PDF."
                                                        value={quoter.contactFinishedText || ''}
                                                        onChange={(e) => {
                                                            setQuoter({ ...quoter, contactFinishedText: e.target.value });
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <iframe
                                            ref={calculatorRef}
                                            id="calculator-frame"
                                            style={{ border: 'none' }}
                                            title="Calculator preview"
                                            src={calculatorKey}
                                            width="100%"
                                            height="680px"
                                        />
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
};

export default Quoter;
