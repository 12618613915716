import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import AuthProvider from 'components/AuthProvider';

import { transitions, positions, Provider as AlertProvider } from '@blaumaus/react-alert';
import { ConfirmDialogProvider } from 'react-mui-confirm';
import AlertTemplate from './AlertTemplate';

// types
import { openDrawer } from 'store/reducers/menu';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const dispatch = useDispatch();

    const { drawerOpen } = useSelector((state) => state.menu);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    const options = {
        // you can also just use 'bottom center'
        position: positions.BOTTOM_RIGHT,
        timeout: 5000,
        offset: '15px',
        // you can also just use 'scale'
        transition: transitions.SCALE,
        containerStyle: {
            zIndex: 100,
            pointerEvents: 'all',
            maxWidth: '420px',
            right: '10px',
            bottom: '10px',
            left: 'auto'
        }
    };

    return (
        <AuthProvider>
            <AlertProvider template={AlertTemplate} {...options}>
                <ConfirmDialogProvider>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
                        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
                        <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                            <Toolbar />
                            <Breadcrumbs navigation={navigation} divider={false} />
                            <Outlet />
                        </Box>
                    </Box>
                </ConfirmDialogProvider>
            </AlertProvider>
        </AuthProvider>
    );
};

export default MainLayout;
